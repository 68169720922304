














import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import PeriodCloseFirst from '@/components/periodClose/PeriodCloseFirst.vue';
@Component({
  components: {
    PeriodCloseFirst,
  },
})
export default class PeriodClose extends BaseVue {}
