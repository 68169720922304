

























































































































































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';

import { Org } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { stringifyError } from '@/utils/error';

import AppLink from '../AppLink.vue';
import UiAlert from '../ui/UiAlert.vue';
import UiButton from '../ui/UiButton.vue';
import UiDatePicker from '../ui/UiDatePicker.vue';
import UiModal from '../ui/UiModal.vue';

@Component({
  components: {
    UiAlert,
    UiModal,
    UiDatePicker,
    UiButton,
    AppLink,
  },
})
export default class PeriodCloseFirst extends BaseVue {
  public get currentOrg(): Org {
    return this.$store.state.currentOrg;
  }

  public hardCloseEditDateOpen = false;
  public newHardCloseDate: string | null = null;
  public hardCloseLoading = false;
  public hardCloseWarningOpen = false;

  public hardCloseAuditIcons = {
    set: { icon: 'fa-solid fa-lock', color: 'tw-text-green-500' },
    unset: { icon: 'fa-solid fa-unlock', color: 'tw-text-red-500' },
    update: { icon: 'fa-solid fa-pencil', color: 'tw-text-blue-500' },
  };

  localeFormatDate(date: string) {
    const d = new Date(date); // this will create the date in UTC
    const regionalDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()); // this stage will convert the date to the local timezone
    return regionalDate.toLocaleDateString(); // this will format the date to the locale
  }

  hardCloseOn() {
    this.newHardCloseDate = null;
    this.hardCloseEditDateOpen = true;
  }

  public async hardCloseOff() {
    await this.updateHardClose(null);
  }

  hardCloseEdit() {
    this.newHardCloseDate = this.currentOrg.hardCloseDate ?? null;
    this.hardCloseEditDateOpen = true;
  }

  async updateHardClose(date: string | null) {
    this.hardCloseLoading = true;
    try {
      const resp = await this.$apollo.mutate({
        mutation: gql`
          mutation updateOrg($orgId: ID!, $hardCloseDate: String!) {
            updateOrg(orgId: $orgId, hardCloseDate: $hardCloseDate) {
              id
            }
          }
        `,
        variables: {
          orgId: this.currentOrg.id,
          hardCloseDate: date === null ? '' : date,
        },
      });
      if (resp.errors) {
        this.showErrorSnackbar(resp.errors.join('<br />'));
      }
      await Promise.all([this.$store.dispatch('updateOrgs')]);
    } catch (e) {
      this.showErrorSnackbar(stringifyError(e, { hideErrorName: true }).replace('GraphQL error: ', ''));
      console.error(e);
    } finally {
      this.hardCloseLoading = false;
    }
  }
}
